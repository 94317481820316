/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Sean Overton",
  title: "Hi all, I'm Sean",
  subTitle: emoji(
    "A passionate Full Stack Software Engineer 🚀 with experience as both a software engineer and cloud engineer."
  ),
  // resumeLink:
    // "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/SeanOverton",
  linkedin: "https://www.linkedin.com/in/sean-overton-806a1920b/",
  // gmail: "saadpasta70@gmail.com",
  // gitlab: "https://gitlab.com/saadpasta",
  // facebook: "https://www.facebook.com/saad.pasta7",
  // medium: "https://medium.com/@saadpasta",
  // twitter: '',
  stackoverflow: "https://stackoverflow.com/users/17584582/sean-o?tab=profile",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "A passionate Full Stack Engineer with an eye for finding high-impact work within projects and teams.",
  skills: [
    emoji(
      "⚡Develop modern Front end's for your web applications"
    ),
    emoji("⚡Engineer scalable and resilient backend API services"),
    emoji(
      "⚡Leverage cloud native solutions with expertise in AWS services"
    ),
    emoji(
      "⚡Build robust automation pipelines for CI/CD"
    ),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    // {
    //   skillName: "html-5",
    //   fontAwesomeClassname: "fab fa-html5"
    // },
    // {
    //   skillName: "css3",
    //   fontAwesomeClassname: "fab fa-css3-alt"
    // },
    // {
    //   skillName: "sass",
    //   fontAwesomeClassname: "fab fa-sass"
    // },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    // {
    //   skillName: "swift",
    //   fontAwesomeClassname: "fab fa-swift"
    // },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    // {
    //   skillName: "sql-database",
    //   fontAwesomeClassname: "fas fa-database"
    // },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    // {
    //   skillName: "firebase",
    //   fontAwesomeClassname: "fas fa-fire"
    // },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of Wollongong",
      logo: require("./assets/images/uow.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "July 2019 - Nov 2022",
      desc: "Dean's Merit List (top 5% of cohort) for 3 of 3 years",
      descBullets: [
        "Distinction Average",
        "Highest grade: 97"
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Typescript", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Python",
      progressPercentage: "85%"
    },
    {
      Stack: "AWS",
      progressPercentage: "75%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "Tiktok",
      companylogo: require("./assets/images/tiktok.png"),
      date: "Apr 2024 – Present",
      desc: "I have joined a frontend engineering team and have been building my expertise in advanced FE technical skills and solution design",
    },
    {
      role: "Software Engineer",
      company: "PEXA",
      companylogo: require("./assets/images/pexa.jpg"),
      date: "Feb 2023 – Apr 2024",
      desc: "At PEXA I worked as a software engineer and also spent time in the cloud engineering team.",
      descBullets: [
        "I spent 6 months in a cloud engineering team where I learnt best practices with terraform and AWS",
        "Contributed to a Full Stack application (typescript based) using KoaJS, NextJS deployed on EKS, Lambdas and S3",
        "Worked extensively with AWS S3, Lambdas, ECS, API Gateway for solutions"
      ]
    },
    {
      role: "Software Engineer Intern",
      company: "Deferit",
      companylogo: require("./assets/images/deferit.png"),
      date: "Jul 2021 – Jun 2022",
      desc: "During my 12 month internship, I honed my skill with the python programming language.",
      descBullets: [
        "I learnt strict best practices in OOP when working in the django backend",
        "I worked with the python library selenium to automate tests as ewll as tasks for the operations team of the business",
        "I learnt the importance of using appropriate design patterns to make complex functionality easier to comprehend, maintain and scale when I helped to productionalise a new system"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      // image: require("./npm assets/images/saayaHealthLogo.webp"),
      projectName: "Saayahealth",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://saayahealth.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      // image: require("./assets/images/nextuLogo.webp"),
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://nextu.se/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Certifications 🏆 "),
  // subtitle:
    // "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Terraform Certified Associate",
      image: require("./assets/images/tf.png"),
      imageAlt: "Terraform Certified Associate Badge",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.credly.com/badges/16691572-92b0-4c09-a154-e6a7e5524f8f"
        },
      ]
    },
    {
      title: "AWS Certified Cloud Practitioner",
      image: require("./assets/images/aws1.png"),
      imageAlt: "AWS Certified Cloud Practitioner Badge",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.credly.com/badges/7e007e67-d3d3-4496-91ce-a6d63e505ded"
        },
      ]
    },
    {
      title: "AWS Certified Solutions Architect – Associate",
      image: require("./assets/images/aws2.png"),
      imageAlt: "AWS Certified Solutions Architect – Associate Badge",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.credly.com/badges/ce57c72f-67dd-459a-baea-e00538609a8e"
        },
      ]
    },
    {
      title: "KCNA: Kubernetes and Cloud Native Associate",
      image: require("./assets/images/KCNA_badge.png"),
      imageAlt: "KCNA: Kubernetes and Cloud Native Associate Badge",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.credly.com/badges/ff3d1b7d-da76-4ede-a8e6-4c663be2d784"
        },
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "In an age when we are bombarded with information, I try to write short but powerful articles on insights I pick from the books I read.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://dev.to/s3an_0vert0n/8-tips-from-the-engineers-survival-guide-13d6",
      title: "8 Tips from the 'Engineers Survival Guide'",
      description:
        "My highlights from the classic 'Engineers Survival Guide' book."
    },
    {
      url: "https://dev.to/s3an_0vert0n/10-quick-tips-to-instantly-improve-api-design-59mc",
      title: "10 Quick tips to instantly improve API design",
      description:
        "My top insights from the 'REST API Design Rulebook' by Mark Masse."
    },
    {
      url: "https://dev.to/s3an_0vert0n/a-quick-functional-programming-recap-2o0d",
      title: "A quick Functional Programming recap",
      description:
        "Functional Programming: What? Why? and How?"
    },
    {
      url: "https://dev.to/s3an_0vert0n/a-practical-introduction-to-gitops-2eao",
      title: "A practical introduction to GitOps",
      description:
        "A complete tutorial and walkthough of a codebase explaining GitOps practically"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle: "Check out my work or connect",
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
